<template>
  <div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="cosmos_box">
            <div class="display_flex justify-content_flex-center align-items_center margin-tb">
                <div class="luck_nav margin-lr" :style="{backgroundImage: 'url(img/cosmos/'+ (navActive==index?'luck_active':'luck_costom') +'.png)'}" v-for="(item,index) in nav" :key="index" @click="changeNav(index)">{{item.name}}</div>
            </div>
            <div class="ranking_list">
                <div class="ranking_item display_flex justify-content_flex-justify align-items_center" v-for="(item,index) in indexData" :key="index">
                    <div class="display_flex align-items_center">
                        <img class="ranking_img" :src="item.head_portrait" alt="">
                        <div class="margin-left">
                            <div class="display_flex align-items_center">
                                <div class="ranking_name text-white">{{item.nickname}}</div>
                                <div class="ranking_level" :style="{backgroundImage: `url(img/level/level0/${item.user_wealth_level}.png)`}"></div>
                            </div>
                            <div class="ranking_sign text-white">ID:{{item.uid}}</div>
                        </div>
                    </div>
                    <div class="ranking_number">
                        <img :src="'img/cosmos/'+(index+1)+'.png'" alt="">
                    </div>
                </div>
            </div>
            <div class="noData margin-tb-xl" v-if="noData">
              <img src="img/noData.png" alt="">
            </div>
        </div>
    </van-pull-refresh>
  </div>
</template>

<script>
  import {goldEggsList} from "@/api/ranking";
  export default {
    name: 'luckRank',
    props: {
    },
    data() {
      return {
        navActive:0,
        nav:[
          {
            name:"日榜",
            type:"day"
          },
          {
            name:"周榜",
            type:"week"
          }
        ],
        isLoading: false,
        indexData:[],
        noData:false,
      };
    },
    created() {
      this.getData()
    },
    methods: {
      getData(){
        let type = this.nav[this.navActive].type
        goldEggsList({date_type:type}).then(response => {
          this.indexData = response.data
          this.isLoading = false
          if(response.data.length==0){
            this.noData = true
          }else{
            this.noData = false
          }
        },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
      },
      changeNav(e){
          this.navActive = e
          this.getData()
      },
      onRefresh() {
            this.getData()
      },
      checkUser(id){
        let param = id.toString()
        this.$bridge.callhandler('checkUser', param, (data) => {
        // 处理返回数据
        })
      },

    },
  };
</script>