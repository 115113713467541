<template>
  <div>
    <div class="cos_Bg" style="background-image: url(img/cosmos/cos_bg.png);">
        <div class="display_flex justify-content_flex-justify align-items_center margin-lr">
            <div class="nav_item" :class="{'nav_active' : navActive==index }" :style="{backgroundImage: 'url(img/cosmos/'+ (navActive==index?'nav_active':'nav_costom') +'.png)'}" v-for="(item,index) in nav" :key="index" @click="changeNav(index)">{{item}}</div>
        </div>
        <!-- 玩法介绍和拆出记录 -->
        <div class="goIntroduce" style="background-image: url(img/cosmos/introduce.png);" @click="goIntroduce"></div>
        <div class="goRecord" style="background-image: url(img/cosmos/record.png);" @click="goRecord"></div>
        <!-- 奖品 -->  
        <div v-show="navActive == 0">
            <award-gift />
        </div>
        <!-- 手气榜 -->
        <div v-show="navActive == 1">
            <luck-rank />
        </div>
        <!-- 排行榜 -->
        <div v-show="navActive == 2">
            <ranking-list />
        </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/cosmos.css"
import awardGift from './awardGift.vue';
import luckRank from './luckRank.vue';
import rankingList from './rankingList.vue';
export default {
  name: 'cosmosCube',
  components: {
    awardGift,
    luckRank,
    rankingList
  },
  data () {
    return {
      nav:['奖品','手气榜','排行榜'],
      navActive:0,
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
  },
  methods: {
    changeNav(e){
        this.navActive = e
    },
    // closePage(){
    //   console.log('执行了')
    //   let params = '调用'
    //   this.$bridge.callhandler('closePage', params, (data) => {
    //   // 处理返回数据
    //   })
    // },
    goIntroduce(){
        this.$router.push({path:'/cosmosIntroduce'})
    },
    goRecord(){
        this.$router.push({path:'/recordList'})
    },
    
  }
}
</script>
