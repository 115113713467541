<template>
  <div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="cosmos_hint" style="background-image: url(img/cosmos/box1.png);">
          打开任意“神秘星球”礼物将100%获得一个随机礼物，每种礼物的获得概率将随着奖池中剩余礼物数量变化而动态变化。奖池礼物如下：
        </div>
        <div class="cosmos_box">
          <div class="padding-bottom-xl" v-for="(item,index) in navList" :key="index">
              <div class="cos_tit" style="background-image: url(img/cosmos/title1.png);">
                  <span v-if="index=='small'">魔法星球</span>
                  <span v-if="index=='big'">快乐星球</span>
              </div>
              <van-loading v-if="pageLoading" class="margin-tb-xl text-center" color="#fff" />
              <div class="display_flex align-items_center flex_wrap margin-bottom-lg">
                  <div class="award_item" v-for="(data,index) in item" :key="index">
                      <div class="award_box display_flex justify-content_flex-center">
                          <van-image class="award_image" fit="contain" :src="data.gift_url"/>
                          <img v-if="data.diamonds >= 4999" class="award_hint" src="img/cosmos/gift_hint.png" alt="">
                          <div class="award_zuan display_flex align-items_center justify-content_flex-center">
                            <img src="img/bi.png" alt="">
                            <span>{{data.diamonds}}</span>
                          </div>
                      </div>
                      <div class="award_name">{{data.gift_name}}</div>
                      <!-- <div class="award_num">X{{item.num}}</div> -->
                  </div>

              </div>
            </div>
            
        </div>
    </van-pull-refresh>
  </div>
</template>

<script>
  import {prizeDisplay} from "@/api/ranking";
  export default {
    name: 'awardGift',
    props: {
    },
    data() {
      return {
        isLoading: false,
        navList:[],
        pageLoading:true
      };
    },
    created() {
      this.getData()
    },
    methods: {
      getData(){
        prizeDisplay().then(response => {
          this.navList = response.data
          this.pageLoading = false
          this.isLoading = false
        },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
      },

      onRefresh() {
          this.getData()
      },

    },
  };
</script>

<style>
.van-loading{
    color: #F4FAFF;
}
.van-loading__text{
    color: #F4FAFF; 
}
.van-pull-refresh__head{
    color: #F4FAFF; 
}
</style>