<template>
  <div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="cosmos_box">
        <div class="display_flex justify-content_flex-center align-items_center margin-tb">
          <div class="luck_nav margin-lr"
            :style="{ backgroundImage: 'url(img/cosmos/' + (navActive == index ? 'luck_active' : 'luck_costom') + '.png)' }"
            v-for="(item, index) in navList" :key="index" @click="changeNav(index)">{{ item.gift_name }}</div>
        </div>
        <div class="luck_list">
          <div class="luck_item display_flex justify-content_flex-justify align-items_center"
            v-for="(item, index) in indexData" :key="index"
            :style="{ backgroundImage: 'url(img/cosmos/' + (item.diamonds >= 4999 ? 'luck_bg2' : 'luck_bg1') + '.png)' }">
            <div class="margin-left">
              <div class="luck_name"><span>{{ item.nickname }}</span>获得礼物</div>
              <div class="display_flex align-items_center">
                <p class="luck_gift">{{ item.prize_name }}</p>
                <div class="luck_zuan display_flex align-items_center justify-content_flex-center">
                  <img src="img/bi.png" alt="">
                  <span>{{ item.diamonds }}</span>
                </div>
              </div>
            </div>
            <div class="margin-right display_flex align-items_center">
              <div class="luck_box">
                <van-image class="luck_image" fit="contain" :src="item.gift_url" />
                <div class="luck_amout">x{{ item.num }}</div>
              </div>
              <div class="luck_num margin-left-sm" style="background-image: url(img/cosmos/luck_num.png);">
                {{ item.total_num }}次</div>
            </div>
          </div>
        </div>
        <div class="noData margin-tb-xl" v-if="noData">
          <img src="img/noData.png" alt="">
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { boxLuckyRank } from "@/api/ranking";
export default {
  name: 'luckRank',
  props: {
  },
  data() {
    return {
      navActive: 0,
      navList: [{ gift_name: '魔法星球', id: 1 }, { gift_name: '快乐星球', id: 2 }],
      isLoading: false,
      indexData: [],
      noData: false,
      page: 1,
      rows: 10
    };
  },
  created() {
    this.fetchData()
  },
  mounted() {

    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    fetchData() {
      let id = this.navList[this.navActive].id
      boxLuckyRank({ eggs_id: id, rows: this.rows, page: this.page }).then(response => {
        this.isLoading = false
        this.indexData = this.indexData.concat(response.data)
        if (this.indexData.length == 0) {
          this.noData = true
        } else {
          this.noData = false
        }
        this.page = this.page + 1
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })

    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.fetchData()
      }
    },

    changeNav(e) {
      this.navActive = e
      this.indexData = []
      this.page = 1
      this.fetchData();
    },
    onRefresh() {
      this.indexData = []
      this.page = 1
      this.fetchData()
    },

  },
};
</script>